<template>
  <BaseButton
    id="header-mobile-login-btn"
    class="button-custom base-button--btn-transparent"
    @click="onClickAuthor(MODAL_TYPES.LOGIN, 'login')"
  >
    Đăng nhập
  </BaseButton>
  <BaseButton
    id="header-mobile-register-btn"
    class="button-custom base-button--btn-primary"
    @click="onClickAuthor(MODAL_TYPES.LOGIN, 'register')"
  >
    Đăng ký
  </BaseButton>
</template>
<script setup>
import BaseButton from '~/components/common/base-button.vue'
import { MODAL_TYPES } from '~/config/constant'
const { onClickAuthor } = useHeader()
</script>
<style lang="scss" scoped />
